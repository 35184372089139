/*
 *
 * file: RenderToPage.js
 *
 * purpose:
 *   -
 *
 */

var mustache = require('mustache');

var RenderToPage = function(data, divID, template) {
    var DefaultTemplate = "<div class='testList'>{{#posts}}";
    DefaultTemplate += "<a href='{{&link}}'><h1>{{title}}</h1></a>";
    DefaultTemplate += "<p>{{excerpt}}</p>";
    DefaultTemplate += "{{/posts}}</div>";

    if (!data) {
        throw new Error("ccSanity: no data provided; can't render without data!");
    }

    if (Array.isArray(data) && data.length === 0) {
        throw new Error("ccSanity: data must an array with at least one element!");
    }

    if (template && typeof template !== "string") {
        throw new Error("ccSanity: templates must be a string")
    }

    if (!template) {
        template = DefaultTemplate;
    }

    // target must be present
    if (divID === null || typeof divID === "undefined") {
        throw new Error("ccSanity: a target is required to render the data to the page");
    }
    if (divID.charAt(0) !== "#") {
        throw new Error("ccSanity: render target must identify an ID with a #");
    }
    if (divID === "#") {
        throw new Error("ccSanity: hey, you need more than an octothorpe to render to a page");
    }

    var targetID = document.getElementById(divID.substring(1));


    if (targetID === undefined || targetID === null) {
        throw new Error("ccSanity: looks like there is no ID for the content...check the page?");
    }

    var renderedOutput = mustache.render(template, data);
    document.getElementById(divID.substring(1)).innerHTML = renderedOutput;

    return true;
};

module.exports = RenderToPage;
