var axios = require('axios');

var ValidateOptions = require('ValidateOptions');
var RenderToPage = require('RenderToPage');
var ParseSanityResponse = require('ParseSanityResponse');

var App = function(options, template, callback) {

    if (template && typeof template !== "string") {
        throw new Error("ccSanity: templates must be a string")
    }

    if (!ValidateOptions(options)) {
        throw new Error("Whoops! ValidateOptions error!");
    }

    var allPosts = [];
    var sourceCount = options.endpoints.length;
    function processAjaxData(apiData) {
        if (apiData !== '') {
            // This assumes that apiData is a formatted like a list of WordPress posts.
            // TODO: check the format of the string.
            apiData.data.forEach(function(post) {
                allPosts[allPosts.length] = post;
            });
        }
        if (--sourceCount == 0) {
            // Sort all posts together, most recent first.
            // (Comparing date strings "YYYY-MM-DDThh:mm:ss" alphabetically does the right thing.)
            allPosts.sort(function(a, b) {
                return (a.date > b.date)
                    ? -1
                    : 1;
            });
            if (allPosts.length > 0) {
                // if 4 or fewer posts, hide the Show More button
                if (allPosts.length <= 4) {
                    // note - if 'news-list' isn't in the string, button equals the target DIV ID
                    // this is why we compare button to options.target, to be sure we're not
                    // hiding the target div when there are <5 posts.
                    var button = options.target.replace("news-list", "button");
                    if (button !== options.target) {
                        jQuery(button).hide();
                    }
                }

                // Pick apart the "post" objects into objects that are easier to render in mustache and limit result count.
                var sanityResponse = ParseSanityResponse(allPosts, 8);

                RenderToPage(sanityResponse, options.target, template);
            } 
            else {
                var section = options.target.replace("list", "section");
                jQuery(section).hide();
            }

            callback();
        }
    }

    options.endpoints.forEach(function(endpoint) {

        var ajaxUrl = endpoint.url + '/articles/newsandmore';
        var queryString = '';

        if (endpoint.postCount) {
            queryString = 'per_page=' + endpoint.postCount;
        }
        if (endpoint.filters && typeof endpoint.filters == 'object') {
            
            for (var attr in endpoint.filters) {
                if (/apps|providers|institutes|categories/.test(attr))
                {
                    queryString += (queryString == '') ? '' : '&' + attr + '=' + endpoint.filters[attr];
                }
            }
        }

        if (queryString && queryString.length)
        {
            ajaxUrl += '?' + queryString;
        }

        axios.get(ajaxUrl, {
            headers: {
                'api_key': endpoint.apiKey
            }
        }).then(function(apiData) {
            processAjaxData(apiData);
        }).catch(function(response) {
            if (response instanceof Error) {
                console.log("ccSanity: Error with ajax call", response.message);
            } 
            else {
                console.log('request problem, response is: ' + response.status);
            }
            processAjaxData('');
        });
    });
}

module.exports = App;
