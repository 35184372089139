/*
 *
 * file: ValidateOptions.js
 *
 * purpose:
 *   - takes a schema to test against
 *   - takes a default object to use as defaults (for when required are missing)
 *   - takes an options object as an argument
 *
 */

var ValidateOptions = function(options) {

    // options.url is required (it is the Sanity base URL)
    // options.endpoint is an object with the following properties on it:
      // options.endpoint.url is the base URL for the Sanity API in question
      // options.endpoint.type is an optional string, and is 'posts', 'pages', 'categories', and 'authors'
      // options.endpoint.postCount is a number from 1-100 that defines the number of types to return
    // options.category is a string
    // options.target is a required string. It is the ID of the div where the final processed output goes.

    // bail if no options passed
    if (typeof options === 'undefined' || options === null) {
        return false;
    }

	// target must be present
    if (options.target === null || typeof options.target === "undefined") {
        throw new Error("ccSanity: options.target is required");
    }
    if (!(options.target.charAt(0) === "#" )) {
        throw new Error("ccSanity: options.target must identify an ID with a #");
    }

	if (typeof options.endpoints != 'object' || !Array.isArray(options.endpoints) || options.endpoints.length == 0) {
		throw new Error("ccSanity: options.endpoints must be a non-empty array of objects.");
	}

	options.endpoints.forEach(function(endpoint) {
		// URL is required, must be a string, and must be a valid url
		if (typeof endpoint.url !== 'string' && endpoint.url !== '') {
			throw new Error("ccSanity: endpoint must include a target url");
		}
		if (!(/^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(endpoint.url)) && !endpoint.url.includes('localhost')) {
			throw new Error("ccSanity: endpoint must include a target url");
		}

		// the type needs to be a string in the array ['posts','pages','categories','authors']
		var allowableTypes = ['posts', 'pages', 'categories', 'authors'];
		if (endpoint.type && typeof endpoint.type !== 'string') {
			throw new Error("ccSanity: endpoint must include a valid type");
		}
		if (endpoint.type && allowableTypes.indexOf(endpoint.type) === -1) {
			throw new Error("ccSanity: endpoint must include a valid type");
		}

		// postCount must be a number from 1-100
		if (endpoint.postCount && typeof endpoint.postCount !== 'number') {
			throw new Error("ccSanity: endpoint.postCount must be a number from 1 to 100");
		}
		if (endpoint.postCount && (endpoint.postCount < 0 || endpoint.postCount > 100)) {
			throw new Error("ccSanity: endpoint.postCount must be a number from 1 to 100");
		}
	});

    return options;
};

module.exports = ValidateOptions;
