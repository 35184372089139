/*
 *
 * file: ParseSanityResponse.js
 *
 * purpose:
 *   - accepts the response from WP-API
 *   - validates the JSON is complete and a valid response is contained within
 *   - returns a re-formatted (flattened) object to use for rendering via mustache
 *
 */

var ParseSanityResponse = function(data, postLimit) {

    //let parsedData = {
    var parsedData = {
        posts: []
    };

    if (!data) {
        throw Error("ccSanity: Sanity data must be provided for parsing");
    }

    if (Array.isArray(data) && data.length === 0) {
        throw Error("ccSanity: data from the server is empty array; something is wrong");
    }

    var validPostCount = 0;

    for (var postNumber = 0; postNumber < data.length; postNumber++) {
        var post = data[postNumber];
        var rendered = {};

        // Don't include post if it's invalid (must have title & url)
        if (!post.title || !post.url)
        {
            continue;
        }

        rendered.date = post.dateline;
        rendered.title = post.title;
        rendered.excerpt = post.excerpt;
        rendered.link = post.url;
        rendered.mediumImage = '';
        try {
            rendered.mediumImage = post.imageUrl;
        } 
        catch (err) {}

        parsedData['posts'].push(rendered);

        validPostCount++;
        if (validPostCount >= postLimit) {
            break;
        }
    }

    return parsedData;
};

module.exports = ParseSanityResponse;
